import {axios} from '@/utils/axios';

const RESOURCE_NAME = '/reports';

export default {
    report_settings(report_name, add_id=null) {
        return axios.get(`${RESOURCE_NAME}/user-report-settings`,
            {params: {report_name: report_name, add_id: add_id}})
    },
    stats() {
        return axios.get(`${RESOURCE_NAME}/stats`)
    },
    reading_statement(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_statement`, payload)
    },
    flat_by_norm_and_counter(payload) {
        return axios.post(`${RESOURCE_NAME}/flat_by_norm_and_counter_report`, payload)
    },
    flat_by_norm_and_counter_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/flat_by_norm_and_counter_report_xlsx`, payload, {responseType: 'blob'})
    },
    flat_by_norm_and_counter_email(payload) {
        return axios.post(`${RESOURCE_NAME}/flat_by_norm_and_counter_report_email`, payload)
    },
    reading_statement_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_statement_xlsx`, payload, {responseType: 'blob'})
    },
    reading_statement_email(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_statement_email`, payload)
    },
    general_report(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report`, payload)
    },
    general_report_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_legacy`, payload)
    },
    general_report_legacy_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_legacy_email`, payload)
    },
    general_report_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_email`, payload)
    },
    privilege_report_blank_2(payload) {
        return axios.post(`${RESOURCE_NAME}/privilege_report_blank_2`, payload)
    },
    privilege_report_blank_2_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/privilege_report_blank_2_xlsx`, payload, {responseType: 'blob'})
    },
    pays_by_date_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_report_by_flat`, payload)
    },
    pays_by_date_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_report_by_flat_xlsx`,
            payload, {responseType: 'blob'})
    },
    pays_by_date_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_report_by_flat_email`,
            payload)
    },
    pays_by_service_and_date_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_and_date_report_by_flat`, payload)
    },
    pays_by_service_and_date_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_and_date_report_by_flat_xlsx`,
            payload, {responseType: 'blob'})
    },
    pays_by_service_and_date_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_and_date_report_by_flat_email`,
            payload)
    },
    pays_by_service_bank_and_date_general_report(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_bank_and_date_general_report`, payload)
    },
    pays_by_service_bank_and_date_general_report_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_bank_and_date_general_report_xlsx`,
            payload, {responseType: 'blob'})
    },
    pays_by_service_bank_and_date_general_report_email(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_service_bank_and_date_general_report_email`,
            payload)
    },
    indicator_check_contract_and_service_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_check_contract_and_service_report_by_flat`, payload)
    },
    indicator_check_contract_and_service_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_check_contract_and_service_report_by_flat_xlsx`, payload, {responseType: 'blob'})
    },
    indicator_check_contract_and_service_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_check_contract_and_service_report_by_flat_email`, payload)
    },
    indicator_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_report_by_flat`, payload)
    },
    indicator_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_report_by_flat_xlsx`, payload, {responseType: 'blob'})
    },
    indicator_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_report_by_flat_email`, payload)
    },
    indicator_report_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_report_by_flat_legacy`, payload)
    },
    indicator_report_by_flat_legacy_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/indicator_report_by_flat_legacy_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_flat_by_service(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service`, payload)
    },
    general_report_by_flat_by_service_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service_legacy`, payload)
    },
    general_report_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_legacy`, payload)
    },
    general_report_by_flat_legacy_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_legacy_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_flat_legacy_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_legacy_email`, payload)
    },
    new_general_report_with_grouping(payload) {
        return axios.post(`${RESOURCE_NAME}/new-general-report-with-grouping`, payload)
    },
    universal_general_report_by_grouping(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-general-report-by-grouping`, payload)
    },
    universal_general_report_by_grouping_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-general-report-by-grouping-xlsx`, payload, {responseType: 'blob'})
    },
    universal_general_report_by_grouping_email(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-general-report-by-grouping-email`, payload)
    },
    dispatcher_calls_stats(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-stats`, payload)
    },
    dispatcher_calls_stats_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-stats-xlsx`, payload, {responseType: 'blob'})
    },
    dispatcher_calls_stats_email(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-stats-email`, payload)
    },
    dispatcher_calls_history(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-history`, payload)
    },
    dispatcher_calls_history_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-history-xlsx`, payload, {responseType: 'blob'})
    },
    dispatcher_calls_history_email(payload) {
        return axios.post(`${RESOURCE_NAME}/dispatcher-calls-history-email`, payload)
    },
    universal_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-report-by-flat`, payload)
    },
    universal_spreed_sheet_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-spreed-sheet-report-by-flat`, payload)
    },
    universal_spreed_sheet_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-spreed-sheet-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    universal_spreed_sheet_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-spreed-sheet-report-by-flat-email`, payload)
    },
    universal_pretense_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-pretense-report-by-flat`, payload)
    },
    universal_pretense_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-pretense-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    universal_pretense_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-pretense-report-by-flat-email`, payload)
    },
    universal_court_case_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-court-case-report-by-flat`, payload)
    },
    universal_court_case_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-court-case-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    universal_court_case_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-court-case-report-by-flat-email`, payload)
    },
    universal_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    universal_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/universal-report-by-flat-email`, payload)
    },
    general_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat`, payload)
    },
    general_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_email`, payload)
    },
    general_report_by_flat_by_service_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_flat_by_service_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service_email`, payload)
    },
    general_report_by_flat_by_service_legacy_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service_legacy_xlsx`, payload, {responseType: 'blob'})
    },
    general_report_by_flat_by_service_legacy_email(payload) {
        return axios.post(`${RESOURCE_NAME}/general_report_by_flat_by_service_legacy_email`, payload)
    },
    contract_stats(payload) {
        return axios.post(`${RESOURCE_NAME}/contract_stats`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    contract_stats_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/contract_stats_xlsx`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        }, {responseType: 'blob'})
    },
    contract_stats_email(payload) {
        return axios.post(`${RESOURCE_NAME}/contract_stats_email`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            email: payload.email
        })
    },
    pays_by_date_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_by_flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    pays_by_date_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_by_flat_xlsx`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        }, {responseType: 'blob'})
    },
    pays_by_date_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/pays_by_date_by_flat_email`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            email: payload.email
        })
    },
    debt_group_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    debt_group_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    debt_group_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat_email`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters,
                email: payload.email
            })
    },
    debt_group_report_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat_legacy`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    debt_group_report_by_flat_legacy_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat_legacy_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    debt_group_report_by_flat_legacy_email(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_group_report_by_flat_legacy_email`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters,
                email: payload.email
            })
    },
    debt_report_by_pages(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_pages`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    debt_report_by_pages_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_pages_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    debt_report_by_pages_email(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_pages_email`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters,
            email: payload.email
        })
    },
    debt_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    restructuring_agreement_status(payload) {
        return axios.post(`${RESOURCE_NAME}/restructuring_agreement_status`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    restructuring_agreement_status_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/restructuring_agreement_status_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    restructuring_agreement_status_email(payload) {
        return axios.post(`${RESOURCE_NAME}/restructuring_agreement_status_email`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters,
                email: payload.email
            })
    },
    debt_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    debt_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat_email`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters,
                email: payload.email
            })
    },
    debt_report_by_flat_legacy(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat_legacy`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    debt_report_by_flat_legacy_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat_legacy_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters
            },
            {responseType: 'blob'})
    },
    debt_report_by_flat_legacy_email(payload) {
        return axios.post(`${RESOURCE_NAME}/debt_report_by_flat_legacy_email`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters,
                email: payload.email
            })
    },
    receipt_simply(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_simply`, payload)
    },
    receipt_simply_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_simply_xlsx`, {
                date_start: payload.date_start,
                date_end: payload.date_end,
                filters: payload.filters || {}
            },
            {responseType: 'blob'}
        )
    },
    receipt_simply_email(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_simply_email`, payload)
    },
    receipt_simply_email_by_one(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_simply_email_by_one`, payload)
    },
    receipt_detail(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail`, payload)
    },
    receipt_detail_email(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_email`, payload)
    },
    receipt_detail_email_by_one(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_email_by_one`, payload)
    },
    receipt_detail_with_email(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_with_email`, payload)
    },
    receipt_detail_with_email_by_one(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_with_email_by_one`, payload)
    },
    receipt_detail_with(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_with`, payload)
    },
    warning_default(payload) {
        return axios.post(`${RESOURCE_NAME}/warning_default`, payload)
    },
    warning_default_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/warning_default_xlsx`, payload, {responseType: 'blob'})
    },
    warning_default_email(payload) {
        return axios.post(`${RESOURCE_NAME}/warning_default_email`, payload)
    },
    receipt_detail_with_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_with_xlsx`, payload, {responseType: 'blob'})
    },
    receipt_detail_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/receipt_detail_xlsx`, payload, {responseType: 'blob'})
    },
    archive_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/archive_report_by_flat`, payload)
    },
    archive_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/archive_report_by_flat_xlsx`, payload, {responseType: 'blob'})
    },
    archive_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/archive_report_by_flat_email`, payload)
    },
    certificate_204_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/certificate_204_xlsx`, payload, {responseType: 'blob'})
    },
    certificate_204_email(payload) {
        return axios.post(`${RESOURCE_NAME}/certificate_204_email`, payload)
    },
    unemployment_book_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/unemployment_book_xlsx`, payload, {responseType: 'blob'})
    },
    unemployment_book_email(payload) {
        return axios.post(`${RESOURCE_NAME}/unemployment_book_email`, payload)
    },
    unemployment_witness_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/unemployment_witness_xlsx`, payload, {responseType: 'blob'})
    },
    unemployment_witness_email(payload) {
        return axios.post(`${RESOURCE_NAME}/unemployment_witness_email`, payload)
    },
    family_members_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/family_members_xlsx`, payload, {responseType: 'blob'})
    },
    family_members_email(payload) {
        return axios.post(`${RESOURCE_NAME}/family_members_email`, payload)
    },
    indebtedness_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/indebtedness_xlsx`, payload, {responseType: 'blob'})
    },
    indebtedness_email(payload) {
        return axios.post(`${RESOURCE_NAME}/indebtedness_email`, payload)
    },
    live_place_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/live_place_xlsx`, payload, {responseType: 'blob'})
    },
    live_place_email(payload) {
        return axios.post(`${RESOURCE_NAME}/live_place_email`, payload)
    },
    reading_balance(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_report_balance`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    statement_building_list_info(payload) {
        return axios.post(`${RESOURCE_NAME}/statement_building_list_info`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    statement_building_list_info_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/statement_building_list_info_xlsx`, payload, {responseType: 'blob'})
    },
    statement_building_list_info_email(payload) {
        return axios.post(`${RESOURCE_NAME}/statement_building_list_info_email`, payload)
    },
    restructuring_agreement_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/restructuring_agreement_xlsx`, payload, {responseType: 'blob'})
    },
    restructuring_agreement_email(payload) {
        return axios.post(`${RESOURCE_NAME}/restructuring_agreement_email`, payload)
    },
    counter_verification_end(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_verification_end`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    counter_verification_end_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_verification_end_xlsx`, payload, {responseType: 'blob'})
    },
    counter_verification_end_email(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_verification_end_email`, payload)
    },
    counter_count(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_count`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    counter_count_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_count_xlsx`, payload, {responseType: 'blob'})
    },
    counter_count_email(payload) {
        return axios.post(`${RESOURCE_NAME}/counter_count_email`, payload)
    },
    provision_of_services(payload) {
        return axios.post(`${RESOURCE_NAME}/provision_of_services`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    provision_of_services_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/provision_of_services_xlsx`, payload, {responseType: 'blob'})
    },
    provision_of_services_email(payload) {
        return axios.post(`${RESOURCE_NAME}/provision_of_services_email`, payload)
    },
    reading_balance_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_report_balance_xlsx`, payload, {responseType: 'blob'})
    },
    reading_balance_email(payload) {
        return axios.post(`${RESOURCE_NAME}/reading_report_balance_email`, payload)
    },
    removal_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/removal-report-by-flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },
    removal_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/removal-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    removal_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/removal-report-by-flat-email`, payload)
    },
    recalculation_report_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/recalculation-report-by-flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },

    recalculation_report_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/recalculation-report-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    recalculation_report_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/recalculation-report-by-flat-email`, payload)
    },
    person_hash_list_by_flat(payload) {
        return axios.post(`${RESOURCE_NAME}/person-hash-list-by-flat`, {
            date_start: payload.date_start,
            date_end: payload.date_end,
            filters: payload.filters
        })
    },

    person_hash_list_by_flat_xlsx(payload) {
        return axios.post(`${RESOURCE_NAME}/person-hash-list-by-flat-xlsx`, payload, {responseType: 'blob'})
    },
    person_hash_list_by_flat_email(payload) {
        return axios.post(`${RESOURCE_NAME}/person-hash-list-by-flat-email`, payload)
    },


}
