import {formatDate} from "@/filters";


const PORTRAIT_ZOOM_WIDTH = 748
const LANDSCAPE_ZOOM_WIDTH = 1360

const getCurrentDate = () => {
    const date = new Date()
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
    const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
    return `${year}-${month}-${day}`
}

const getPrintDateRange = (date_start, date_end) => {
    if (date_start && date_end) {
        const formatted_date_start = formatDate(date_start, 'MMMM YYYY')
        const formatted_date_end = formatDate(date_end, 'MMMM YYYY')

        if (formatted_date_start === formatted_date_end) {
            return `. Період звіту: ${formatted_date_start}`
        } else {
            return `. Період звіту: з ${formatDate(date_start)} по ${formatDate(date_end)}`
        }
    }
}

const group_by = (iterable, column, exclude_column = 'not set exclude column', excluded_column_2 = 'not set exclude column 1') => {
    const new_obj = []

    iterable.map(item => {
        const column_value = item[column]
        const find = new_obj.find(i => i[column] === column_value)

        if (!find) {
            const row = {[column]: column_value}
            Object.keys(item).filter(key => key !== column).map(key => row[key] = item[key])
            row['items'] = [item]
            new_obj.push(row)
        } else {
            Object.keys(item).filter(key => key !== column).map(key => {
                let exc = key.indexOf(exclude_column) === -1 ? '' : key
                let exc_ = key.indexOf(excluded_column_2) === -1 ? '' : key

                let cont = (key !== exc) && (key !== exc_)

                const item_value = item[key]
                if (cont) {
                    if (typeof item_value === "number") {
                        find[key] += item_value
                    }
                }
            })
            find['items'].push(item)
        }
    })

    return new_obj
}

const isExpanded = (expanded, group_idx, item_idx) => {
    const comb_key = item_idx === undefined ? `${group_idx}` : `${group_idx}-${item_idx}`
    return expanded.includes(comb_key)
}

export {
    PORTRAIT_ZOOM_WIDTH,
    LANDSCAPE_ZOOM_WIDTH,
    getCurrentDate,
    getPrintDateRange,
    group_by,
    isExpanded
}